import { Injectable } from "@angular/core";
import { ISchemaField, UserProductRole } from "../ITypes";
import { AuthService } from "src/app/core/services/auth.service";
import { SiteConfigService } from "./site-config.service";

@Injectable()
export class ChannelService {
  constructor(
    private authService: AuthService,
    private siteConfigService: SiteConfigService
  ) {}

  canViewProductDisplaygroup() {
    return (
      [
        UserProductRole.CHANNEL_ASSORTMENT,
        UserProductRole.PRODUCT_ASSORTMENT,
      ].indexOf(this.authService.authUser.channelProductRole) != -1
    );
  }

  selectProductFieldsByTenant(_schemaControl: ISchemaField[] = []) {
    const hasVisibleField = (item: ISchemaField) => {
      if (
        Array.isArray(item.isVisibleTo) &&
        item.isVisibleTo.indexOf(this.authService.authUser.channelId) === -1
      ) {
        return null;
      }

      if (
        Array.isArray(item.isHiddenFrom) &&
        item.isHiddenFrom.indexOf(this.authService.authUser.channelId) !== -1
      ) {
        return null;
      }

      if (item.type === "dropdown" || item.type === "select") {
        item.data.values = item.data.values.filter((item) => {
          if (
            Array.isArray(item.channelVisibility) &&
            item.channelVisibility.indexOf(
              this.authService.authUser.channelId
            ) === -1
          ) {
            return false;
          }

          if (
            Array.isArray(item.channelHidden) &&
            item.channelHidden.indexOf(this.authService.authUser.channelId) !==
              -1
          ) {
            return false;
          }

          return item;
        });
      }

      this.parsedProductTenantRules(item);

      return item;
    };

    const extractField = (_schemaFields: Array<ISchemaField | null>) => {
      return _schemaFields.filter((item: ISchemaField) => {
        if (!item) {
          return false;
        }
        if (Array.isArray(item) && item.length <= 0) {
          return false;
        }

        return hasVisibleField(item);
      });
    };

    const fieldAccessMapper = (_schemaFields: ISchemaField[]) => {
      return _schemaFields.map((item: ISchemaField) => {
        if (item.type == "panel" || item.type == "inputGroup") {
          item.components = extractField(fieldAccessMapper(item.components));
          return item;
        }

        return hasVisibleField(item);
      });
    };

    const parsedFields = _schemaControl.map((item: ISchemaField) => {
      if (item.type == "panel" || item.type == "inputGroup") {
        const panelCmpList = extractField(fieldAccessMapper(item.components));

        if (panelCmpList.length <= 0) {
          return null;
        }

        item.components = panelCmpList;
        return item;
      } else {
        if (hasVisibleField(item)) {
          return item;
        } else {
          return null;
        }
      }
    });

    return extractField(parsedFields);
  }

  parsedProductTenantRules(item: ISchemaField) {
    if (
      item.key === "P005" &&
      this.siteConfigService.getItem("product.salesTaxPercentage")
    ) {
      item.default = this.siteConfigService.getItem(
        "product.salesTaxPercentage"
      );
    }

    return item;
  }
}
