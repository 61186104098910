import { UserRole } from "../core/ITypes";
import { DELIVERY_METHOD, ORDER_STATUS } from "./ITypes";
export class AppConstant {
  public static hidePandaDisplayGroup: number[] = []; //100102, 100201, 100902

  static orderStaus = [
    {
      label: "Payment received",
      labelKey: "OPTION.OP008",
      value: ORDER_STATUS.PAYMENT_RECEIVED,
    },
    {
      label: "New Order",
      labelKey: "OPTION.OP009",
      value: ORDER_STATUS.NEW_ORDER,
    },
    {
      label: "Confirmation",
      labelKey: "OPTION.OP010",
      value: ORDER_STATUS.CONFIRMATION_SENT,
    },
    {
      label: "Delivery in queue",
      labelKey: "OPTION.OP011",
      value: ORDER_STATUS.NEW,
    },
    {
      label: "Delivery in processing",
      labelKey: "OPTION.OP012",
      value: ORDER_STATUS.DELIVERY_PROCESS,
    },
    {
      label: "Delivery dispatched",
      labelKey: "OPTION.OP013",
      value: ORDER_STATUS.DELIVERY_DISPATCHED,
    },
    {
      label: "Delivery confirmation sent",
      labelKey: "OPTION.OP014",
      value: ORDER_STATUS.DELIVERY_CONFIRMATION_SENT,
    },
    {
      label: "Order reimbursed",
      labelKey: "OPTION.OP015",
      value: ORDER_STATUS.ORDER_REIMBURSED,
    },
    {
      label: "Order completed",
      labelKey: "OPTION.OP016",
      value: ORDER_STATUS.COMPLETED,
    },
    {
      label: "Payment Failed",
      labelKey: "OPTION.OP017",
      value: ORDER_STATUS.PAYMENT_FAILED,
    },
    {
      label: "Cancelled",
      labelKey: "OPTION.OP001",
      value: ORDER_STATUS.CANCELLED,
    },
    {
      label: "Delivery Failed",
      labelKey: "OPTION.OP018",
      value: ORDER_STATUS.DELIVERY_FAILED,
    },
  ];

  static cancelledOrderStatus = {
    key: ORDER_STATUS.CANCELLED,
    value: "cancelled",
    label: "Order cancelled",
    labelKey: "OPTION.OP018",
  };

  static orderStatusGroup = {
    default: {
      new_order: [
        /* ORDER_STATUS.PAYMENT_RECEIVED,
        ORDER_STATUS.NEW_ORDER,
        ORDER_STATUS.CONFIRMATION_SENT, */
        { key: "default.O900.key", value: ORDER_STATUS.PAYMENT_RECEIVED },
        { key: "default.O900.key", value: ORDER_STATUS.NEW_ORDER },
        { key: "default.O900.key", value: ORDER_STATUS.CONFIRMATION_SENT },
        // { key: "default.O007", value: "New order" },
      ],
      new: [
        /* ORDER_STATUS.PAYMENT_RECEIVED,
        ORDER_STATUS.NEW_ORDER,
        ORDER_STATUS.CONFIRMATION_SENT,
        ORDER_STATUS.NEW*/
        { key: "default.O900.key", value: ORDER_STATUS.PAYMENT_RECEIVED },
        { key: "default.O900.key", value: ORDER_STATUS.NEW_ORDER },
        { key: "default.O900.key", value: ORDER_STATUS.CONFIRMATION_SENT },
        { key: "default.O900.key", value: ORDER_STATUS.NEW },
        // { key: "default.O007", value: "New" },
      ],
      in_progress: [
        /* ORDER_STATUS.NEW, ORDER_STATUS.DELIVERY_PROCESS */
        { key: "default.O900.key", value: ORDER_STATUS.NEW },
        { key: "default.O900.key", value: ORDER_STATUS.DELIVERY_PROCESS },
        // { key: "default.O007", value: "In Progress" },
      ],
      completed: [
        /* ORDER_STATUS.DELIVERY_DISPATCHED,
        ORDER_STATUS.DELIVERY_CONFIRMATION_SENT,
        ORDER_STATUS.ORDER_REIMBURSED,
        ORDER_STATUS.COMPLETED, */
        { key: "default.O900.key", value: ORDER_STATUS.DELIVERY_DISPATCHED },
        {
          key: "default.O900.key",
          value: ORDER_STATUS.DELIVERY_CONFIRMATION_SENT,
        },
        { key: "default.O900.key", value: ORDER_STATUS.ORDER_REIMBURSED },
        { key: "default.O900.key", value: ORDER_STATUS.COMPLETED },
        // { key: "default.O007", value: "Completed" },
      ],
      not_paid: [
        /* ORDER_STATUS.PAYMENT_FAILED */
        {
          key: "default.O900.key",
          value: ORDER_STATUS.PAYMENT_FAILED,
        },
        /* {
          key: "default.O007",
          value: "Not paid",
        }, */
      ],
      cancelled: [
        /* ORDER_STATUS.CANCELLED, ORDER_STATUS.DELIVERY_FAILED */
        { key: "default.O900.key", value: ORDER_STATUS.CANCELLED },
        { key: "default.O900.key", value: ORDER_STATUS.DELIVERY_FAILED },
        // { key: "default.O007", value: "Cancelled" },
      ],
    },
    fallback: {
      new_order: "New order",
      new: "New",
      in_progress: "In Progress",
      completed: "Completed",
      not_paid: "Not paid",
      cancelled: "Cancelled",
    },
  };

  static orderStatusDropdown = [
    {
      label: "New order just in",
      labelKey: "OPTION.OP025",
      value: "new_order",
    },
    { label: "Order can be edited", labelKey: "OPTION.OP026", value: "new" },
    {
      label: "Order in processing",
      labelKey: "OPTION.OP027",
      value: "in_progress",
    },
    { label: "Order completed", labelKey: "OPTION.OP028", value: "completed" },
    { label: "Order not paid", labelKey: "OPTION.OP029", value: "not_paid" },
    {
      label: "Order not delivered",
      labelKey: "OPTION.OP030",
      value: "cancelled",
    },
  ];

  static orderReasons = [
    {
      label: "Not delivered",
      labelKey: "OPTION.OP031",
      value: "Not delivered",
    },
    {
      label: "Broken package",
      labelKey: "OPTION.OP032",
      value: "Broken package",
    },
    { label: "Spoiled", labelKey: "OPTION.OP033", value: "Spoiled" },
    { label: "Expired", labelKey: "OPTION.OP034", value: "Expired" },
    {
      label: "Wrong product",
      labelKey: "OPTION.OP035",
      value: "Wrong product",
    },
  ];

  static lifecycle = [
    { label: "Incoming", labelKey: "OPTION.OP001", value: 1 },
    { label: "On Sale", labelKey: "OPTION.OP002", value: 2 },
    { label: "Paused", labelKey: "OPTION.OP003", value: 3 },
    { label: "Removed", labelKey: "OPTION.OP004", value: 4 },
  ];

  static displayGroupStatuses = [
    { label: "Hidden", labelKey: "OPTION.OP020", value: 0 },
    { label: "Show", labelKey: "OPTION.OP021", value: 1 },
    { label: "Archive", labelKey: "OPTION.OP022", value: 2 },
  ];

  static productStatuses = [
    {
      label: "In assortment",
      labelKey: "OPTION.OP005",
      value: "in-assortment",
    },
    {
      label: "Not in assortment",
      labelKey: "OPTION.OP006",
      value: "not-in-assortment",
    },
    {
      label: "Not published",
      labelKey: "OPTION.OP007",
      value: "not-published",
    },
  ];

  static postalDeliveryMethod = {
    smartship: [DELIVERY_METHOD.SMARTSHIP, DELIVERY_METHOD.SMARTSHIP_ALT],
    collico: [DELIVERY_METHOD.COLLICO],
  };

  static userRoles = [
    { label: "Admin", labelKey: "OPTION.OP023", value: UserRole.ADMIN },
    { label: "User", labelKey: "OPTION.OP024", value: UserRole.USER },
  ];

  static supplierWeeks = [
    { label: "Week 1 / Monday", value: 1 },
    { label: "Week 1 / Tuesday", value: 2 },
    { label: "Week 1 / Wednesday", value: 3 },
    { label: "Week 1 / Thursday", value: 4 },
    { label: "Week 1 / Friday", value: 5 },
    { label: "Week 1 / Saturday", value: 6 },
    { label: "Week 1 / Sunday", value: 7 },
    { label: "Week 2 / Monday", value: 8 },
    { label: "Week 2 / Tuesday", value: 9 },
    { label: "Week 2 / Wednesday", value: 10 },
    { label: "Week 2 / Thursday", value: 11 },
    { label: "Week 2 / Friday", value: 12 },
    { label: "Week 2 / Saturday", value: 13 },
    { label: "Week 2 / Sunday", value: 14 },
  ];
}
