import { Inject, Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

import { IProduct } from "../ITypes";
import {
  ApiService,
  AuthService,
  ChannelService,
  HttpQueryService,
} from "src/app/core/services";
import { GeneralService } from "./general.service";
import {
  IAppConfig,
  IProductQueryParams,
  IQueryParams,
} from "src/app/core/ITypes";
import { SupplierService } from "./supplier.service";
import { UnitService } from "./unit.service";
import { productFormSchema } from "src/app/layout/product/write/schema";
import { ProductValidator } from "src/app/layout/product/utils/product.validator";
import { VendorService } from "./vendor.service";
import { ProductDisplayGroup } from "src/app/layout/product/entity";
import { SupplierTitle } from "src/app/layout/supplier/entity";
import { isEmptyVal } from "../utils";
import { VendorTitle } from "src/app/layout/vendor/entity";
import { APP_CONFIG } from "src/app/config";
import { isNumeric } from "../utils";

@Injectable()
export class ProductService {
  // defaults
  form = [];
  units = [];
  queryParams: IQueryParams = {
    limit: 200,
    offset: 0,
    count: 0,
  };

  constructor(
    private api: ApiService,
    private generalService: GeneralService,
    private supplierService: SupplierService,
    private channelService: ChannelService,
    private vendorService: VendorService,
    public unitService: UnitService,
    private httpQueryService: HttpQueryService,
    private authService: AuthService,
    @Inject(APP_CONFIG) private config: IAppConfig
  ) {}

  get getForm() {
    return this.form;
  }

  get productFormSchema() {
    return productFormSchema;
  }

  get getUnits() {
    return this.units.map((u) => ({
      label: u.volume_title,
      value: u.volume_title,
      extra: {
        C002: u.product_type,
        P006: u.reference_unit,
        U007: u.conversion_ratio || u.volume_conversion,
      },
    }));
  }

  async getProductFormSchema() {
    try {
      const units = await this.unitService.getUnits(this.queryParams);
      const dropdownQueryParams = Object.assign({}, this.queryParams, {
        limit: this.config.maxDropdownOptions,
      });
      this.units = units.result;
      const suppliers = await this.supplierService.getSuppliers(
        dropdownQueryParams
      );
      const vendors = await this.vendorService.getVendors(dropdownQueryParams);

      const { status, result } = productFormSchema;
      // const { status, result } = await this.api
      //   .get("/form/product")
      //   .toPromise();

      if (status === 200 && result && result.properties) {
        const panels: Array<any> = result.properties.components.map((panel) => {
          if (panel.allowedAccessRoles) {
            if (
              panel.allowedAccessRoles.indexOf(
                this.authService.authUser.channelProductRole
              ) !== -1
            ) {
              panel.hidden = false;
            } else {
              panel.hidden = true;
            }
          }

          return panel;
        });
        const filteredAccessPanels =
          this.channelService.selectProductFieldsByTenant(panels as any);
        this.form = filteredAccessPanels;

        //let displayGroupField = { data: { values: [] } };
        let supplierField = { data: { values: [] } };
        let vendorField = { data: { values: [] } };
        let unitField = { data: { values: [] } };

        for (let i = 0; i < filteredAccessPanels.length; i++) {
          const panel = filteredAccessPanels[i];
          const _displayGroupField = panel.components.find(
            (c) => c.key === "D001"
          );
          /* if (_displayGroupField) {
            displayGroupField = _displayGroupField;
          } */
          const _supplierField = panel.components.find((c) => c.key === "S002");
          const _vendorField = panel.components.find((c) => c.key === "S011");
          if (_supplierField) {
            supplierField = _supplierField;
          }
          if (_vendorField) {
            vendorField = _vendorField;
          }
          const _unitField = panel.components.find((c) => c.key === "U005");
          if (_unitField) {
            unitField = _unitField;
          }
        }
        supplierField.data.values = suppliers.result.map((s) => ({
          label: SupplierTitle.format(s),
          value: s.supplier_id,
        }));
        vendorField.data.values = vendors.result.map((s) => ({
          label: VendorTitle.format(s),
          value: s.code,
        }));
        unitField.data.values = this.getUnits;

        return filteredAccessPanels;
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * skip (defaults to 0)
   * limit (defaults to 500)
   * order (defaults to id) queryparams
   * @param {IQuery} queryParams
   * @returns products
   * @memberof ProductService
   */
  async getProducts(queryParams: IProductQueryParams) {
    try {
      const limit = queryParams.limit;
      const offset = queryParams.offset;
      const search = queryParams.search?.trim();
      const displayGroup = queryParams.displayGroup;
      const productStatus = queryParams.status;
      const direction = queryParams.direction;
      const order = queryParams.order;
      const S002 = queryParams.S002;
      const S011 = queryParams.S011;
      const A007 = queryParams.A007;

      let apiEndpoint = `/product?limit=${limit}&skip=${offset}&sort=${order}:${direction}`;

      if (search) {
        let searchKey = "C005";

        if (search.includes(",")) {
          let extractedSearchTerm = search
            .split(",")
            .map((val) => val.trim())
            .filter((val) => val)
            .join("&search=S004=");

          apiEndpoint += `&search=S004=${extractedSearchTerm}`;
        } else {
          const searchTerm = search.trim();

          if (!isNaN(Number(searchTerm))) {
            const handleIsGTIN = this.generalService.handleIsGTIN(searchTerm);
            searchKey = handleIsGTIN ? "S004" : "C001";
          }

          apiEndpoint += `&search=${searchKey}=${searchTerm}`;
        }
      }

      if (displayGroup) {
        apiEndpoint += `&filter=D001=${displayGroup}`;
      }

      if (S002) {
        apiEndpoint += `&filter=S002=${S002}`;
      }

      if (S011) {
        apiEndpoint += `&filter=S011=${S011}`;
      }

      if (A007) {
        apiEndpoint += `&filter=A007=${A007}`;
      }

      if (productStatus) {
        if (productStatus === "in-assortment") {
          apiEndpoint += `&filter=C003=true&filter=D002=true`;
        }
        if (productStatus === "not-in-assortment") {
          apiEndpoint += `&filter=C003=true&filter=D002=false`;
        }
        if (productStatus === "not-published") {
          apiEndpoint += `&filter=C003=false`;
        }
      }

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        let data = [];
        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          let props = res.properties;
          let price = props.P003;
          let campaignPrice = props.M002;
          const productNetPrice = isNumeric(props.P003)
            ? props.P003
            : props.P002;

          // price
          price = this.generalService.parseAmount(price);
          props.P003 = price;

          // campaign price
          campaignPrice = this.generalService.parseAmount(campaignPrice);
          props.M002 = campaignPrice;

          //transform sort into number format
          if (props.S007 != null) {
            props.S007 = parseInt(props.S007);
          }

          if (props.D000) {
            props.D000 = ProductDisplayGroup.selectDisplayGroups(props.D000);
          }

          data.push({
            id: res.id,
            ...props,
            metaInfo: {
              productNetPrice,
              productNetPriceFormatted:
                this.generalService.parseAmount(productNetPrice),
            },
          });
        }

        return { result: data, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * skip (defaults to 0)
   * limit (defaults to 500)
   * order (defaults to id) queryparams
   * @param {IQuery} queryParams
   * @returns retail prices
   * @memberof ProductService
   */
  async getRetailPrice(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const offset = queryParams.offset;
      const direction = queryParams.direction;

      let apiEndpoint = `/price/format?limit=${limit}&skip=${offset}&direction=${direction}`;

      const { status, result } = await this.api.get(apiEndpoint).toPromise();

      if (status === 200 && result && result.length) {
        return { result };
      }
      return { result: [] };
    } catch (error) {
      throw error;
    }
  }

  async getProduct(id: string) {
    try {
      const { status, result } = await this.api
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const productEntity = this.buildProduct(result.properties);

        return productEntity;
      }
    } catch (error) {
      throw error;
    }
  }

  buildProduct(entity) {
    const product = { ...entity };

    if (isEmptyVal(product.D000)) {
      product.D000 = [];
    }

    return product;
  }

  async postProductFormData(data: IProduct) {
    try {
      const payload = {
        properties: data,
      };
      const { status, result } = await this.api
        .post(payload, "/product")
        .toPromise();

      if (status === 200 && result) {
        let props = result.properties;
        return {
          id: result.id,
          ...props,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async updateProductFormData(id: string, data: IProduct) {
    try {
      const payload = {
        properties: data,
        id,
      };
      const { status, result } = await this.api
        .put(payload, `/product`)
        .toPromise();

      if (status === 200 && result) {
        let props = result.properties;
        return {
          id: result.id,
          ...props,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async postProductImageData(url: string, files: FileList) {
    try {
      let formData: FormData = new FormData();

      for (let i = 0; i < files.length; i++) {
        let file: any = files[i];
        formData.append("files", file, file.name);
      }

      const headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      const { status, result } = await this.api
        .post(formData, url || "/upload", {}, headers)
        .toPromise();

      if (status === 200 && result) {
        return result.result;
      }
    } catch (error) {
      throw error;
    }
  }

  getSchemaField(schema, fieldKey) {
    let foundField;

    schema.every((panel) => {
      const matchedField = panel.components.find((cmp) => cmp.key == fieldKey);
      if (matchedField) {
        foundField = matchedField;
        return false;
      }
      return true;
    });

    if (foundField) {
      return foundField;
    }
  }

  async searchProduct(params = null) {
    try {
      const filter = params.filter;
      const limit = 25;

      const querySet = { filter, limit };

      const queryString = this.httpQueryService.serialize(querySet);

      let apiEndpoint = `/product?${queryString}`;

      const { status, result, meta } = await this.api
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        let data = [];
        for (let i = 0; i < result.length; i++) {
          const res = result[i];
          let props = res.properties;

          data.push({
            id: res.id,
            ...props,
          });
        }

        return { result: data, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  async validateDeliveryCode(keyName = "S004", code, productID) {
    const validate = await ProductValidator.isDeliveryCodeExist(
      {
        key: keyName,
        value: code,
        id: productID,
      },
      this.searchProduct.bind(this)
    );

    return validate;
  }

  hasBeforeDisplayGroup(product) {
    return product.D001 && (!product.D000 || product?.D000?.length == 0);
  }
}
